import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';

import './Tabel.css';

function createData(amount, first, second, approved) {
  return { amount, first, second, approved };
}

const rows = [
  createData(1, 'Jason ', 'Mike', 'Delivered'),
  createData(2, 'Michael ', 'Jordan', 'Delivered'),
  createData(3, 'Olivia ', 'Emma', 'Not Delivered'),
  createData(4, 'Mia ', 'George', 'Not Delivered'),
  createData(5, 'Charlotte ', 'Jean', 'Delivered'),
  createData(6, 'Nancy ', 'Hoenie', 'Not Delivered'),
  createData(7, 'Michael ', 'Walls', 'Delivered'),
  createData(8, 'Bridge ', ' Austin', 'Not Delivered'),
  createData(9, 'James ', 'Robin', 'Delivered'),
  createData(10, 'Pat ', 'John', 'Delivered'),
  createData(11, 'Sohia ', 'Mary', 'Delivered'),
  createData(12, 'Angel ', 'Williams', 'Delivered'),
  createData(13, 'Jacob ', 'Ethan', 'Not Delivered'),
  createData(14, 'Alexander ', 'Ryan', 'Delivered'),
  createData(15, 'Susan ', 'Dora', 'Delivered'),
  createData(16, 'Dawn ', 'Collins', 'Delivered'),
  createData(17, 'Peggy ', 'Haggerty', 'Not Delivered'),

  createData(18, 'Keith ', 'Drown', 'Delivered'),
  createData(19, 'Tanaquil ', 'LeMaster', 'Delivered'),
  createData(20, 'John ', 'Koranda', 'Delivered'),
  createData(21, 'Trish ', 'Reeder', 'Delivered'),
  createData(22, 'Antonia', 'Mengler', 'Not Delivered'),
  createData(23, 'Nancy', 'Hoenie', 'Not Delivered'),
  createData(24, 'Mark', 'Mustin', 'Not Delivered'),
];

export default function BasicTable() {
  const [done, setDone] = React.useState(false);
  return (
    <div>
      <Typography
        style={{ textAlign: 'center', paddingBottom: 50, fontSize: 25 }}
      >
        List of Winners
      </Typography>
      <Typography
        style={{ textAlign: 'center', paddingBottom: 30, fontSize: 15 }}
      >
        Note: If your Winning money hasnt been delivered to you it means you
        havent paid your necessary fee , immediately you contact the Agent for
        your necessary fee payment, your money will be delivered as soon as
        possible with your Approval code
      </Typography>
      <TableContainer component={Paper} style={{ padding: '0px 20px' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow className='all'>
              <TableCell className='tab'></TableCell>
              <TableCell className='tab' align='right'>
                First Name
              </TableCell>
              <TableCell className='tab' align='right'>
                Last Name
              </TableCell>
              {/* <TableCell align='right' style={{ color: 'blue', fontSize: 20 }}>
                Sex
              </TableCell> */}
              <TableCell className='tab' align='right'>
                Approved
              </TableCell>
              {/* <TableCell
                align='right'
                style={{ color: 'blue', fontSize: 20 }}
              ></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                // key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align='th' style={{ color: 'blue' }}>
                  {row.amount}
                </TableCell>
                <TableCell align='right'>{row.first}</TableCell>

                <TableCell align='right'>{row.second}</TableCell>

                <TableCell align='right' style={{ fontWeight: '700' }}>
                  {row.approved}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
