import React from 'react';
import Navbar from '../componet/Navbar';
import Winners from '../form/BasicTable';
import Footer from '../componet/Footer';

const Tableswin = () => {
  return (
    <div>
      <Navbar />
      <Winners />
      <Footer />
    </div>
  );
};

export default Tableswin;
