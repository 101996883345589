import { AccountBalance, LocationOn, Person } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { FaPiggyBank, FaWallet } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';

const Dashboard = () => {
  const [inputValue, setInputValue] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const inputValue = JSON.parse(localStorage.getItem('inputValue'));

    if (inputValue) {
      setInputValue(inputValue);
    }
  }, []);

  const handleClick = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <>
      <div>
        <Navbar />
      </div>

      <section className='section' style={{ padding: '2rem 3rem' }}>
        <div>
          <h2 style={{ color: 'blue' }}>Dashboard</h2>
          <div
            style={{
              paddingTop: '2rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Person style={{ fontSize: 40, fontWeight: 'bold' }} />
            <div style={{ marginLeft: 15, marginTop: 15 }}>
              <span style={{ fontSize: 20 }}>{inputValue.firstName}</span>{' '}
              <span style={{ fontSize: 20 }}> {inputValue.lastName}</span>
            </div>
          </div>
          <div
            style={{
              paddingTop: '1rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LocationOn style={{ fontSize: 40, fontWeight: 'bold' }} />
            <p style={{ marginLeft: 15, fontSize: 20 }}>{inputValue.address}</p>
          </div>
          <div
            style={{
              paddingTop: '1rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FaWallet style={{ fontSize: 30, fontWeight: 'bold' }} />
            <p style={{ marginLeft: 15, fontSize: 20 }}>{inputValue.claim}</p>
          </div>

          <div
            style={{
              paddingTop: '1rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <AccountBalance style={{ fontSize: 30, fontWeight: 'bold' }} />
            <p style={{ marginLeft: 15, fontSize: 20 }}>
              {inputValue.bankname}
            </p>
          </div>
        </div>

        <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
          <h3>
            Congratulations on your well-deserved International Country Lottery{' '}
            {''}
            {inputValue.firstName} {''}
            {inputValue.lastName}
          </h3>
          <p>
            I'm delighted to inform you that your application was successful and
            you just won a sum of <strong>{inputValue.claim}</strong>
          </p>
        </div>

        <p style={{ paddingTop: '2rem', fontWeight: 'bold' }}>
          An agent will contact you as soon as possible in order for you to get
          your winning money{' '}
        </p>
        <span>Kindly click to proceed</span>
        <button
          style={{
            border: '1px solid blue',
            width: 100,
            fontSize: 15,
            fontWeight: '700',
            marginLeft: 10,
          }}
          onClick={handleClick}
        >
          Proceed
        </button>
      </section>
      <Footer />
    </>
  );
};

export default Dashboard;
