import React from 'react';
import './About.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import online from '../asset/fin2.jpeg';
import online2 from '../asset/fin.webp';
import fin3 from '../asset/news1.jpeg';
import parent1 from '../asset/parent.webp';
import Sliders from './Slider';
import { Link } from 'react-router-dom';

const About = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='pricing'>
      <div className='container'>
        <div className='top-content'>
          <div data-aos='zoom-in'>
            <h2>
              A short history
              <span> International Country Lottery</span>
            </h2>
            <p>
              International Country Lottery is a global support founded july
              2015, in which Billions of dollars in International lottery are
              awarded each year for the programs and projects that benefit the
              public. This assistance is rooted in the Constitution and its call
              to "promote the general Welfare." It wasn't until the 2021,
              however, that ICL policy began to evolve into what it is today.
            </p>
          </div>
          <div className='btn-div'>
            <Link to='/forms'>
              <button>Get started today</button>
            </Link>
          </div>
        </div>
        <div className='bottom-content'>
          <div className='card'>
            <p>
              <span>ICL Eligibility</span>
            </p>
            <h3>All-in-one platform</h3>
            <p
              data-aos='fade-up'
              data-aos-anchor-placement='center-bottom'
              data-aos-duration='1500'
            >
              Everyone is eligible to apply for the lottery disabled people,
              homeless people, poor people, old people, business establishment,
              payment of bills, debt etc. Providing your necessary document to
              the Agent is vital .
            </p>
            <br />
            <p
              data-aos='fade-up'
              data-aos-anchor-placement='center-bottom'
              data-aos-duration='1500'
            >
              NOTE: We are keeping all the information we are getting from you
              confidential and our data base is fully encrypted,we are not
              letting it out to anybody who is not part of the Board of
              directors , we are honest and we hope you are honest with us too.
            </p>
          </div>
          <div className='card'>
            <div>
              <img
                className='img'
                src={online}
                alt=''
                style={{
                  width: '20rem',
                  height: '15rem',
                  borderRadius: 8,
                  objectFit: 'cover',
                }}
              />
            </div>
            <div>
              <img
                className='img'
                src={online2}
                alt=''
                style={{
                  width: '20rem',
                  height: '15rem',
                  borderRadius: 8,
                  objectFit: 'cover',
                }}
              />
            </div>
          </div>
          <div className='card'>
            <div>
              <img
                className='img'
                src={fin3}
                alt=''
                style={{
                  width: '20rem',
                  height: '15rem',
                  borderRadius: 8,
                  objectFit: 'cover',
                }}
              />
            </div>
            <div>
              <img
                className='img'
                src={parent1}
                alt=''
                style={{
                  width: '20rem',
                  height: '15rem',
                  borderRadius: 8,
                  objectFit: 'cover',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
